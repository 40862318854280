export const ROUTES = {
    LOGIN: "/login",
    REGISTER: "/register",
    HOME: "/home",
    CONFIRM: "/confirm",
    PROJECTS: "/projects",
    PROJECT_DETAILS: "/project/:id",
    CREATE_PROJECT: "/project-create",
    PROJECT_DETAILS_FORMATTED: (id) => "/project/" + id
}

import React, { useState } from 'react';
import './register.css';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { ROUTES } from '../../components/router/routes';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import SelectInput from '@material-ui/core/Select/SelectInput';

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function Register(props) {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const setInput = (fn) => (event) => {
        fn(event.target.value);
  }

  const submit = (event) => {
        event.preventDefault();
        if (!email || !name || !password) {
            alert("All fields are required.");
        }
        else if (!password || password.length < 8) {
            alert("password should be atleast 8 characters.");
        }
        else {
            props.registerRequest(email, name, password);
        }

  }

  return (
      <div className="register">
        <Card className={classes.card}>
            <CardMedia
            className={classes.media}
            image="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80"
            title="Lawyer Documents"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Welcome
                </Typography>
                <Typography gutterBottom component="span">
                    Register
                </Typography>
                <form className={classes.container} noValidate autoComplete="off" onSubmit={submit}>
                    <TextField
                        label="Your Name"
                        className={classes.textField}
                        type="text"
                        name="name"
                        autoComplete="email"
                        margin="normal"
                        variant="filled"
                        fullWidth
                        value={name}
                        onChange={setInput(setName)}
                    />
                    <TextField
                        label="Email"
                        className={classes.textField}
                        type="email"
                        name="email"
                        autoComplete="email"
                        margin="normal"
                        variant="filled"
                        fullWidth
                        value={email}
                        onChange={setInput(setEmail)}
                    />
                    <TextField
                        id="outlined-password-input"
                        label="Password"
                        className={classes.textField}
                        type="password"
                        autoComplete="current-password"
                        margin="normal"
                        variant="filled"
                        fullWidth
                        value={password}
                        onChange={setInput(setPassword)}
                    />
                    <div className="form-actions">
                        <Link component={RouterLink} to={ROUTES.LOGIN}>
                            <Button type="button" size="small" color="primary">Already Registered</Button>
                        </Link>
                        <Button type="submit" size="small" color="primary">
                            Register
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    </div>
  );
}
import { LOGIN_REQUEST, LOGIN_SUCCESS, REGISTER_REQUEST, REGISTER_SUCCESS, CONFIRM_REQUEST } from './actionTypes';

export const loginRequest = (email, password) => {
    return {
        type: LOGIN_REQUEST,
        email,
        password
    }
}

export const loginSuccess = (user) => {
    return {
        type: LOGIN_SUCCESS,
        user,
    }
}

export const registerRequest = (email, name, password) => {
    return {
        type: REGISTER_REQUEST,
        email,
        name,
        password
    }
}

export const registerSuccess = (user) => {
    return {
        type: REGISTER_SUCCESS,
        user,
    }
}

export const confirmRequest = (code, payload) => {
    return {
        type: CONFIRM_REQUEST,
        code,
        payload
    }
}
export const handleResponse = async (request, response) => {
    let result;
    try {
      result = await response.json();
    } catch (err) {
     //response is empty but 200...
      if (response.ok) {
        return {
          result: "OK",
        }
      }
      throw Object.assign(new Error("Unhandled Error"));
    };
    if (!response.ok) {
      const genericMessage = `HTTP ${response.status} (${response.statusText})`;
      throw Object.assign(new Error(genericMessage), {
        error: result,
        status: response.status
      });
    }
    return {
      response, 
      result 
    };
};


export const URLS = {
    PUBLIC_API: "//localhost:8081"
}

export const setInput = (fn) => (event) => {
    fn(event.target.value);
}

export const getAccessToken = () => {
    return localStorage["accessToken"];
};
import { handleResponse, URLS, getAccessToken } from "../../utils";
import AWS from 'aws-sdk';

//TODO FIND ALTERNATIVE!!! use users credentials instead. 
const s3 = new AWS.S3({
    accessKeyId: 'AKIAU7A6HHUS6QULJXZI',
    secretAccessKey: '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'
});
  

export async function getAllProjects() {
    const req = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'BEARER ' + getAccessToken(),
        },
        };
    const url = URLS.PUBLIC_API + "/getAllProjects";
    const response = await fetch(url, req);
    return handleResponse({ url, ...req }, response);
}

export async function uploadFile(file) {
    const data = file;
    const params = {
        Bucket: 'ratio-dev-raw',
        Key: 'fileTest.pdf', 
        Body: JSON.stringify(data, null, 2)
    };
    s3.upload(params, function(s3Err, data) {
        if (s3Err) throw s3Err
        return `File uploaded successfully at ${data.Location}`;
    });
  }
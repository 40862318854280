import { takeLatest, call, delay, put } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { ROUTES } from '../../components/router/routes';
import { customHistory } from '../../components/router/router';
import { login, signup, confirm } from './api';

async function setTokenInLocalstorage(refreshToken, accessToken) {
    await localStorage.setItem('accessToken', accessToken);
    await localStorage.setItem('refreshToken', refreshToken); 
}

function* watchLogin(action) {
    try {
        const { email, password } = action;
        const  { result } = yield call(login, email, password);
        if (result) {
            const { refreshToken, idToken, authenticationFlowType } = result;
            
            if (authenticationFlowType === "USER_SRP_AUTH") {
                yield put(actions.registerSuccess({ email, password }));
                customHistory.push(ROUTES.CONFIRM);
            }
            else {
                yield call(setTokenInLocalstorage, refreshToken.token, idToken.jwtToken);
                yield put(actions.loginSuccess({ id: idToken.payload.aud, email: idToken.payload.email, name: idToken.payload.name }));
                customHistory.push(ROUTES.HOME);
            }   
        }
    }
    catch(e) {
        console.log(e);
    }
}

function* watchRegister(action) {
    try {
        const { email, name, password } = action;
        const { result } = yield call(signup, email, name, password);
        if (result) {
            customHistory.push(ROUTES.CONFIRM);
            yield put(actions.registerSuccess({ email, password }));
        }
    }
    catch(e) {
    }
}

function* watchConfirm(action) {
    try {
        const { code, payload } = action;
        const { result } = yield call(confirm, { code, email: payload.email });

        if (result) {
            yield put(actions.loginRequest(payload.email, payload.password));
        }
    }catch (e) {

    }
}

export default function* rootSaga() {
    yield takeLatest(actionTypes.LOGIN_REQUEST, watchLogin);
    yield takeLatest(actionTypes.REGISTER_REQUEST, watchRegister);
    yield takeLatest(actionTypes.CONFIRM_REQUEST, watchConfirm);
}

import React from "react";
import { Router, Route } from "react-router-dom";
import createBrowserHistory from 'history/createBrowserHistory'
import Container from '@material-ui/core/Container';
import AppBar from '../appBar';
import Login from '../../pages/login';
import Register from '../../pages/register';
import Home from '../../pages/home';
import { ROUTES } from "./routes";
import confirm from "../../pages/confirm";
import Project from "../../pages/project/Project";
import ProjectInfo from "../../pages/project/ProjectInfo";
import ProjectCreationForm from "../../pages/project-creation";

export const customHistory = createBrowserHistory();

function AppRouter() {
  return (
    <Router history={customHistory}>
        <AppBar />
        <Container maxWidth="lg" className="container">
            <Route path="/" exact component={Login} />
            <Route path={ROUTES.LOGIN} component={Login} />
            <Route path={ROUTES.REGISTER} component={Register} />
            <Route path={ROUTES.HOME} component={Home} />
            <Route path={ROUTES.CONFIRM} component={confirm} />
            <Route path={ROUTES.PROJECTS} component={Project} />
            <Route path={ROUTES.PROJECT_DETAILS} component={ProjectInfo} />
            <Route path={ROUTES.CREATE_PROJECT} component={ProjectCreationForm} />
        </Container>
    </Router>
  );
}

export default AppRouter;
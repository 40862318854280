import React, { Component } from "react";
import "./Project.css";
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import { uploadFile } from '../../services/projectService/actions';

class ProjectInfo extends Component{

    constructor(props) {
        super(props);
          this.state = {
            selectedFile: null
          }
        }

    uploadFile = (event) => {
      const file = document.getElementById("file");
      if (file.files && file.files[0]) {
        this.props.uploadFile(file.files[0]);
      }
    }

    render(){
        const { name, date } = this.props;

        const classes = {};
        
        return (
            <div className="project">
              <Card className={classes.card}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Upload court case
                  </Typography>
                  <div className="project__upload">
                    <input id="file" type="file" name="file" onChange={this.onChangeHandler}/>
                  </div>
                </CardContent>
                <CardActions className="project__actions">
                  <Button onClick={this.uploadFile} color="primary" size="small">Upload</Button>
                </CardActions>
              </Card>
            </div>
          );
    }
    onChangeHandler=event=>{

      this.setState({
        selectedFile: event.target.files[0],
        loaded: 0,
      })
      
    }
}


export default connect(undefined,{ uploadFile })(ProjectInfo);
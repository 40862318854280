import { CREATE_PROJECT, CREATE_PROJECT_SUCCESS, GET_ALL_PROJECTS, GET_ALL_PROJECTS_SUCCESS, UPLOAD_FILE, UPLOAD_FILE_SUCCESS } from './actionTypes';

export const createProject = (title) => {
    return {
        type: CREATE_PROJECT,
        title,
    }
}

export const createProjectSuccess = (project) => {
    return {
        type: CREATE_PROJECT_SUCCESS,
        project,
    }
}

export const getAllProjects = () => {
    return {
        type: GET_ALL_PROJECTS
    }
}

export const getAllProjectsSuccess = (projects) => {
    return {
        type: GET_ALL_PROJECTS_SUCCESS,
        projects
    }
}

export const uploadFile = (file) => {
    return {
        type: UPLOAD_FILE,
        file
    }
}

export const uploadFileSuccess = (file) => {
    return {
        type: UPLOAD_FILE_SUCCESS,
        file
    }
}
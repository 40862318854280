    import { LOGIN_SUCCESS, REGISTER_SUCCESS } from './actionTypes';

    export default (state = {}, action) => {
    switch (action.type) {
      case LOGIN_SUCCESS: 
        return {
          ...state,
          user: action.user,
          isAuth: true,
        }
      case REGISTER_SUCCESS: 
        return {
          ...state,
          accountInfo: {
            email: action.user.email,
            password: action.user.password,
          },
        }
      default:
        return state;
    }
  };
  
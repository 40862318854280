import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { ROUTES } from '../router/routes';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function ButtonAppBar(props) {

  const { isAuth, user } = props;

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar color="default" position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={`${classes.title} app-logo`}>
            Ratio App
          </Typography>
          {
            !isAuth && 
            <Link component={RouterLink} to={ROUTES.LOGIN}>
              <Button variant="contained" color="primary">Login</Button>
            </Link>
          }
          {
            isAuth && 
            <p>
              Hello, { user.name }
            </p>
          }
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default ButtonAppBar;
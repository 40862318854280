import { takeLatest, call, delay, put } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { ROUTES } from '../../components/router/routes';
import { customHistory } from '../../components/router/router';
import { pathToFileURL } from 'url';
import { getAllProjects, uploadFile } from './api';
function* watchCreateProject(action) {
    try {
        const { title } = action;
        const current_datetime = new Date()
        const formatted_date = current_datetime.getDate() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getFullYear()
        const project = { id: -1, name: title, date: formatted_date };
        yield put(actions.createProjectSuccess(project));
        customHistory.push(ROUTES.HOME);
    
    } catch (e) {
        console.log(e);
    }
}

function* watchGetAllProjects(action) {
    try { 
        const {result} = yield call(getAllProjects);
        yield put(actions.getAllProjectsSuccess(result));
    } catch(e) {
        console.log(e);
    }
}

function* watchUploadFile(action) {
    try{
        const { file } = action;
        console.log("here");
        const response = yield call(uploadFile, file)

    }
    catch(e) {
        console.log(e);
    }
}

export default function* rootSaga() {
    yield takeLatest(actionTypes.CREATE_PROJECT, watchCreateProject);
    yield takeLatest(actionTypes.GET_ALL_PROJECTS, watchGetAllProjects);
    yield takeLatest(actionTypes.UPLOAD_FILE, watchUploadFile);
}

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { setInput } from '../../utils';

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function Login(props) {
  const classes = useStyles();

  const [code, setCode] = useState("");

  const confirmEmail = () => {
        if (!code) {
            return;
        }
        props.confirmRequest(code, props.accountInfo);
  }

  return (
      <div className="login">
        <Card className={classes.card}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Your account was created!
                </Typography>
                <Typography gutterBottom component="span">
                    Please check your email, and enter the code to activate your account.
                </Typography>
                <form className={classes.container} noValidate autoComplete="off">
                    <TextField
                        label="Validation Code"
                        className={classes.textField}
                        margin="normal"
                        variant="filled"
                        fullWidth
                        value={code}
                        onChange={setInput(setCode)}
                    />
                    <div className="form-actions">
                        <Button onClick={confirmEmail} size="small" color="primary">
                            Confirm
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    </div>
  );
}
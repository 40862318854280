    import { CREATE_PROJECT_SUCCESS, GET_ALL_PROJECTS_SUCCESS } from './actionTypes';

    export default (state = {}, action) => {
    switch (action.type) {
      case CREATE_PROJECT_SUCCESS: 
        return {
          ...state,
          projects: [...(state.projects || []), action.project]
        }
      case GET_ALL_PROJECTS_SUCCESS: 
        return {
          ...state,
          projects: action.projects
        }
      default:
        return state;
    }
  };
  